<template>
  <div>
    <div class="brand-logo-wrapper">
      <b-link class="brand-logo">
        <div class="d-flex align-items-center">
          <div>
            <router-link to="/">
              <TurtleRentLogo height="100px" width="100px" />
            </router-link>
          </div>
          <div class="ml-1">
            <h1 class="font-weight-bold m-0 p-0">
              Security
            </h1>
          </div>
        </div>

      </b-link>
      <div class="text-center pt-1">
        <p>Protect your Exxtra account with an extra security layer</p>
      </div>
    </div>
  </div>
</template>

<script>
import { BLink } from 'bootstrap-vue';
import TurtleRentLogo from '@core/layouts/components/Logo.vue';

export default {
  name: "SecurityLogo",
  components: {
    BLink,
    TurtleRentLogo
  }
}
</script>

<style scoped>

</style>
